import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import shareImage from "./icons/share-icon.jpg";

import { Header, ProgressBar } from "./components/layout";
import { FormWizard } from "./components/form";
import { Box } from "./components";
import { Landing } from "./pages";

function App() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <meta property="og:url" content="www.blbk.link" />
        <meta property="og:title" content="Blackbook" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Find trusted talent from your extended network"
        />
        <meta property="og:image" content={shareImage} />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:width" content="1080" />
      </Helmet>
      <Router>
        <Box pt={3} textAlign="center">
          <Switch>
            <Redirect from={`/:slug`} exact to={`/:slug/name`} />
            <Route path="/:slug">
              <ProgressBar />
              <Header />
              <FormWizard />
            </Route>
            <Route path="/">
              <Landing />
            </Route>
          </Switch>
        </Box>
      </Router>
    </>
  );
}

export default App;
