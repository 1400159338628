import styled from "styled-components";
import { color, space, typography, layout, variant } from "styled-system";

export const TextArea = styled.textarea`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${variant({
    scale: "inputs",
  })}
`;
