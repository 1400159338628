import styled from "styled-components";
import {
  color,
  space,
  flexbox,
  layout,
  typography,
  position,
  background,
  border,
} from "styled-system";

export const Box = styled.div`
  ${color}
  ${space}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${background}
  ${border}
`;
