import React from "react";
import { useHistory } from "react-router-dom";
import { Field, useFormikContext } from "formik";

import { Box, Text, TextArea } from "../";
import { FormPage } from "./";

const StyledTextArea = (props) => (
  <TextArea
    variant="text"
    width={1}
    minHeight={200}
    mt={2}
    {...props}
    style={{ resize: "none" }}
    maxlength={400}
  />
);

export const ContactDetail = () => {
  const {
    values: { firstName, lastName },
    isValid,
  } = useFormikContext();
  const trimmedFirst = firstName.trim();
  const trimmedLast = lastName.trim();
  const history = useHistory();

  const handleSubmit = () => {
    if (isValid) {
      history.push("socials");
    }
  };

  const title = (
    <>
      Why do you want to recommend{" "}
      <Text variant="focus">
        {trimmedFirst}&nbsp;{trimmedLast}
      </Text>
      ?
    </>
  );

  return (
    <FormPage
      step={2}
      onNext={handleSubmit}
      backTo="name"
      pageTitle={title}
      pageBody={
        <Box display="flex" mt={{ xs: 3, md: 4 }}>
          <Field
            as={StyledTextArea}
            id="details"
            name="details"
            placeholder="What makes them so great?"
            type="text"
          />
        </Box>
      }
    ></FormPage>
  );
};
