import React from "react";

export const Arrow = () => {
  return (
    <svg
      width="74"
      height="20"
      viewBox="0 0 74 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M2 9.77734H70"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M64.2218 1.99917L70.5858 8.36313C71.3668 9.14418 71.3668 10.4105 70.5858 11.1916L64.2218 17.5555"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <defs>
        <clipPath id="clip0">
          <rect width="74" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
