import * as Yup from "yup";

Yup.addMethod(Yup.object, "atLeastOneOf", function (list) {
  return this.test({
    name: "atLeastOneOf",
    message: "Please include at least one way to contact this person!",
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value == null || list.some((f) => value[f] != null),
  });
});

export const validationSchemas = {
  name: Yup.object({
    firstName: Yup.string().required("Please enter their first name!"),
    lastName: Yup.string().required("Please enter their last name!"),
  }),
  details: Yup.object({
    details: Yup.string()
      .required("Please write a bit about the person you're reccommending!")
      .min(5, `Please write a bit about the person you're reccommending!`)
      .max(
        400,
        ({ max }) =>
          `We ask that you keep your descriptions fairly brief - try below ${max} characters!`
      ),
  }),
  socials: Yup.object({
    socials: Yup.object({
      phone: Yup.string()
        .min(6, "Please enter a valid phone number")
        .max(13, "Please enter a valid phone number"),
      email: Yup.string(),
      instagram: Yup.string(),
      website: Yup.string(),
      twitter: Yup.string(),
      facebook: Yup.string(),
      youtube: Yup.string(),
      whatsapp: Yup.string(),
    }).atLeastOneOf([
      "phone",
      "email",
      "instagram",
      "website",
      "twitter",
      "facebook",
      "youtube",
      "whatsapp",
    ]),
  }),
  referrer: Yup.object({
    submitter: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    }),
    acceptedTerms: Yup.bool().oneOf([true]),
  }),
};

//export const defaultValues = {
//  firstName: "Joe",
//  lastName: "Bloggs",
//  details: "Great designer",
//  socials: {
//    phone: "07393980682",
//    email: "",
//    instagram: "",
//    website: "",
//    twitter: "",
//    facebook: "",
//    youtube: "",
//    whatsapp: "",
//  },
//  submitter: {
//    firstName: "Alex",
//    lastName: "Painter",
//  },
//  acceptedTerms: false,
//};

export const defaultValues = {
  firstName: "",
  lastName: "",
  details: "",
  socials: {
    phone: "",
    email: "",
    instagram: "",
    website: "",
    twitter: "",
    facebook: "",
    youtube: "",
    whatsapp: "",
  },
  submitter: {
    firstName: "",
    lastName: "",
  },
  acceptedTerms: false,
};
