import React from "react";

export const Website = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="10.5" stroke={fill} />
      <path
        d="M11 16C8.02323 16 5.35298 15.3956 3.44543 14.4419C1.50798 13.4731 0.500002 12.2274 0.500002 11C0.500002 9.77263 1.50798 8.52685 3.44543 7.55813C5.35298 6.60435 8.02323 6 11 6C13.9768 6 16.647 6.60435 18.5546 7.55813C20.492 8.52685 21.5 9.77263 21.5 11C21.5 12.2274 20.492 13.4731 18.5546 14.4419C16.647 15.3956 13.9768 16 11 16Z"
        stroke={fill}
      />
      <path
        d="M6 11C6 8.02323 6.60435 5.35298 7.55813 3.44543C8.52686 1.50798 9.77263 0.500001 11 0.500001C12.2274 0.500002 13.4731 1.50798 14.4419 3.44543C15.3956 5.35298 16 8.02323 16 11C16 13.9768 15.3956 16.647 14.4419 18.5546C13.4731 20.492 12.2274 21.5 11 21.5C9.77263 21.5 8.52685 20.492 7.55813 18.5546C6.60435 16.647 6 13.9768 6 11Z"
        stroke={fill}
      />
      <path
        d="M11 12.5625C7.98363 12.5625 5.26594 12.333 3.31397 11.967C2.33388 11.7832 1.57202 11.5696 1.06707 11.3457C0.812868 11.233 0.651495 11.1297 0.56127 11.047C0.540188 11.0277 0.525579 11.012 0.515579 11C0.525579 10.988 0.540188 10.9723 0.56127 10.953C0.651495 10.8703 0.812868 10.767 1.06707 10.6543C1.57202 10.4304 2.33388 10.2168 3.31397 10.033C5.26594 9.66703 7.98363 9.4375 11 9.4375C14.0164 9.4375 16.7341 9.66703 18.686 10.033C19.6661 10.2168 20.428 10.4304 20.9329 10.6543C21.1871 10.767 21.3485 10.8703 21.4387 10.953C21.4598 10.9723 21.4744 10.988 21.4844 11C21.4744 11.012 21.4598 11.0277 21.4387 11.047C21.3485 11.1297 21.1871 11.233 20.9329 11.3457C20.428 11.5696 19.6661 11.7832 18.686 11.967C16.7341 12.333 14.0164 12.5625 11 12.5625ZM0.495321 10.9701C0.495397 10.9701 0.495842 10.9709 0.496429 10.9727C0.495537 10.9709 0.495245 10.9701 0.495321 10.9701ZM0.496429 11.0273C0.49584 11.0291 0.495396 11.0299 0.495321 11.0299C0.495247 11.0299 0.495539 11.0291 0.496429 11.0273ZM21.5047 11.0299C21.5046 11.0299 21.5042 11.0291 21.5036 11.0273C21.5045 11.0291 21.5048 11.0299 21.5047 11.0299ZM21.5036 10.9727C21.5042 10.9709 21.5046 10.9701 21.5047 10.9701C21.5048 10.9701 21.5045 10.9709 21.5036 10.9727Z"
        stroke={fill}
      />
      <path
        d="M9.4375 11C9.4375 7.98363 9.66704 5.26594 10.033 3.31397C10.2168 2.33388 10.4304 1.57202 10.6543 1.06707C10.767 0.812868 10.8703 0.651495 10.953 0.56127C10.9723 0.540188 10.988 0.525579 11 0.515579C11.012 0.525579 11.0277 0.540188 11.047 0.56127C11.1297 0.651495 11.233 0.812868 11.3457 1.06707C11.5696 1.57202 11.7832 2.33388 11.967 3.31397C12.333 5.26594 12.5625 7.98363 12.5625 11C12.5625 14.0164 12.333 16.7341 11.967 18.686C11.7832 19.6661 11.5696 20.428 11.3457 20.9329C11.233 21.1871 11.1297 21.3485 11.047 21.4387C11.0277 21.4598 11.012 21.4744 11 21.4844C10.988 21.4744 10.9723 21.4598 10.953 21.4387C10.8703 21.3485 10.767 21.1871 10.6543 20.9329C10.4304 20.428 10.2168 19.6661 10.033 18.686C9.66703 16.7341 9.4375 14.0164 9.4375 11ZM11.0299 0.495321C11.0299 0.495397 11.0291 0.495842 11.0273 0.496429C11.0291 0.495537 11.0299 0.495245 11.0299 0.495321ZM10.9727 0.496429C10.9709 0.49584 10.9701 0.495395 10.9701 0.495321C10.9701 0.495247 10.9709 0.495539 10.9727 0.496429ZM10.9701 21.5047C10.9701 21.5046 10.9709 21.5042 10.9727 21.5036C10.9709 21.5045 10.9701 21.5048 10.9701 21.5047ZM11.0273 21.5036C11.0291 21.5042 11.0299 21.5046 11.0299 21.5047C11.0299 21.5048 11.0291 21.5045 11.0273 21.5036Z"
        stroke={fill}
      />
    </svg>
  );
};
