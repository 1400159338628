import create from "zustand";

const useStore = create((set) => ({
  slug: "",
  progress: 0,
  setSlug: (slug) => set({ slug: slug }),
  setProgress: (progress) => set({ progress: progress }),
}));

export default useStore;
