import React from "react";
import { useHistory } from "react-router-dom";
import { Field, useFormikContext } from "formik";

import { Box, Text, TextInput } from "../";
import { FormPage } from "./";

const StyledInput = (props) => (
  <TextInput variant="text" width={1} mt={2} {...props} />
);

export const ContactName = ({ role }) => {
  const { isValid } = useFormikContext();
  const history = useHistory();

  const handleSubmit = () => {
    if (isValid) {
      history.push("details");
    }
  };

  const title = (
    <>What is your {<Text variant="focus">{role}</Text>}&apos;s name?</>
  );

  return (
    <FormPage
      step={1}
      onNext={handleSubmit}
      pageTitle={title}
      pageBody={
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          mt={{ xs: 3, md: 4 }}
        >
          <Box flex={1} mr={["0px", 2]}>
            <Field
              autoComplete="given-name"
              as={StyledInput}
              id="firstName"
              name="firstName"
              placeholder="First name"
              type="text"
            />
          </Box>
          <Box flex={1}>
            <Field
              autoComplete="family-name"
              as={StyledInput}
              id="lastName"
              name="lastName"
              placeholder="Last name"
              type="text"
            />
          </Box>
        </Box>
      }
    />
  );
};
