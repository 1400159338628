import React from "react";

export const Facebook = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05183 18.0819V22L7.74361 19.9172C8.7727 20.2115 9.86661 20.3704 11 20.3704C17.0755 20.3704 22 15.8099 22 10.1852C22 4.56004 17.0755 0 11 0C4.92486 0 0 4.56004 0 10.1852C0 13.3704 1.57909 16.2144 4.05183 18.0819Z"
        fill={fill}
      />
      <path
        d="M9.8258 7.39551L3.89355 13.6286L9.29239 10.6891L12.1132 13.6286L18.012 7.39551L12.673 10.2836L9.8258 7.39551V7.39551Z"
        fill="#19181F"
      />
    </svg>
  );
};
