import styled from "styled-components";
import { color, typography, variant, space } from "styled-system";

export const Text = styled.span`
  ${color}
  ${typography}
  ${space}
  ${variant({
    scale: "texts",
  })}
`;
