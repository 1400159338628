import React from "react";

export const Phone = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.15142 5.09042L5.25259 0.468654C5.06969 0.177042 4.74845 -0.00739383 4.40721 0.0378456C2.53621 0.285892 -0.299131 1.82059 0.0256199 6.6C0.474077 13.2 11.237 22 16.6185 22C20.4915 22 21.7514 19.1512 21.9653 17.2979C22.0064 16.9416 21.808 16.6101 21.4967 16.432L17.2245 13.9868C16.8605 13.7785 16.4051 13.8194 16.0841 14.0894L13.9925 15.8484C13.682 16.1095 13.2426 16.1582 12.8881 15.961C8.49592 13.5173 6.57853 10.7562 6.00749 9.28314C5.89201 8.98523 5.97987 8.6577 6.19008 8.41708L8.05735 6.27968C8.34762 5.94741 8.38585 5.46418 8.15142 5.09042Z"
        fill={fill}
      />
    </svg>
  );
};
