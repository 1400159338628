import React, { useState } from "react";
import { Field, useFormikContext } from "formik";

import { Box, Text, TextInput } from "../";
import { FormPage, SocialButton } from "./";
import { useHistory } from "react-router-dom";

import {
  Email,
  Facebook,
  Instagram,
  Twitter,
  Phone,
  Website,
  Whatsapp,
  Youtube,
} from "../../icons/";

const socials = {
  phone: { hidden: true, icon: Phone },
  email: { hidden: true, icon: Email },
  instagram: { hidden: true, icon: Instagram },
  website: { hidden: true, icon: Website },
  twitter: { hidden: true, icon: Twitter },
  facebook: { hidden: true, icon: Facebook },
  youtube: { hidden: true, icon: Youtube },
  whatsapp: { hidden: true, icon: Whatsapp },
};

const StyledInput = (props) => (
  <TextInput variant="text" width={1} mt={1} {...props} />
);

const capitalize = (l) => l.replace(/^\w/, (c) => c.toUpperCase());

export const ContactSocials = () => {
  const [socialFields, setSocialFields] = useState({ ...socials });
  const {
    values: { firstName },
    isValid,
  } = useFormikContext();
  const history = useHistory();

  const handleSocialClick = (social) => {
    const s = socialFields[social];
    delete socialFields[social]; // when re adding a social row, insert at end of list
    setSocialFields({ ...socialFields, [social]: { ...s, hidden: !s.hidden } });
  };

  const handleSubmit = () => {
    if (isValid) {
      history.push("referrer");
    }
  };

  const title = (
    <>
      How can&nbsp;
      <Text variant="focus">{firstName}</Text>&nbsp;be contacted?
    </>
  );

  return (
    <FormPage
      step={3}
      onNext={handleSubmit}
      backTo="details"
      pageTitle={title}
      pageBody={
        <>
          <Box mt={3}>
            {Object.entries(socialFields).map(([social, meta]) => {
              const display = meta.hidden ? "none" : "block";
              return (
                <Box display={display} key={`${social}-input`}>
                  <Field
                    as={StyledInput}
                    placeholder={capitalize(social)}
                    name={`socials[${social}]`}
                    type="text"
                  ></Field>
                </Box>
              );
            })}
          </Box>
          <Box
            mt={3}
            display="flex"
            flexWrap="wrap"
            justifyContent={["flex-start", "space-around"]}
          >
            {Object.entries(socials).map(([name, values]) => {
              const { icon } = values;
              const isActive = socialFields[name].hidden;
              return (
                <SocialButton
                  key={name}
                  icon={icon}
                  label={capitalize(name)}
                  isActive={isActive}
                  onClick={() => handleSocialClick(name)}
                ></SocialButton>
              );
            })}
          </Box>
        </>
      }
    ></FormPage>
  );
};
