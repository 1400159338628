import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ky from "ky";

import {
  ContactDetail,
  ContactName,
  ContactSocials,
  SubmitSuccess,
  ReferrerName,
} from "./";

import { validationSchemas, defaultValues } from "./validationSchemas";
import { Box, Text } from "../";

import useStore from "../../hooks/useStore";
import { indefiniteArticle } from "../../utils/indefiniteArticle";

const API_HOST =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/api/"
    : "https://motherly-silly-mammoth.gigalixirapp.com/api/";


export const FormWizard = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const setSlug = useStore((state) => state.setSlug);
  const setProgress = useStore((state) => state.setProgress);
  const [role, setRole] = useState();

  const currentPage = location.pathname.match(/[a-z]+$/);
  const slug = location.pathname.match(/^\/(\S+)\//)[1];
  const { path } = match;

  useEffect(() => {
    setSlug(slug);
    ky.get(`${API_HOST}links/${slug}`).then(async (response) => {
      const {
        data: { role },
      } = await response.json();
      setRole(role);
    });
  }, [slug, setSlug]);

  const transformValues = (values) => {
    return {
      reason: values.details,
      firstname: values.firstName,
      surname: values.lastName,
      ...values.socials,
      recommender_name: `${values.submitter.firstName} ${values.submitter.lastName}`,
      link_slug: slug,
    };
  };

  const handleSubmit = async (values, bag) => {
    bag.setSubmitting("false");
    const postBody = { recommendation: transformValues(values) };

    try {
      ky.post(`${API_HOST}recommendations`, {
        json: postBody,
      }).then((response) => {
        if (response.ok) {
          history.push("success");
          setProgress(5);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {role ? (
        <>
          <Helmet>
            <meta
              property="og:title"
              content={`Blackbook - can you recommend ${indefiniteArticle(
                role
              )} ${role}?`}
            />
            <meta
              property="og:description"
              content={`I'm looking for ${indefiniteArticle(
                role
              )} ${role}, do you have any good recommendations?`}
            />
          </Helmet>
          <Formik
            onSubmit={(values, bag) => handleSubmit(values, bag)}
            validationSchema={validationSchemas[currentPage]}
            initialValues={defaultValues}
          >
            {({ handleSubmit, resetForm }) => {
              return (
                <Form onSubmit={handleSubmit} style={{ height: "100%" }}>
                  <Switch>
                    <Route path={`${path}/name`}>
                      <ContactName role={role} />
                    </Route>
                    <Route path={`${path}/details`}>
                      <ContactDetail />
                    </Route>
                    <Route path={`${path}/socials`}>
                      <ContactSocials />
                    </Route>
                    <Route path={`${path}/referrer`}>
                      <ReferrerName />
                    </Route>
                    <Route path={`${path}/success`}>
                      <SubmitSuccess handleReset={resetForm} />
                    </Route>
                  </Switch>
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (
        <Box>
          <Text variant="h2">Loading link info...</Text>
        </Box>
      )}
    </>
  );
};
