import React from "react";

export const Email = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 3H1C0.447715 3 0 3.44772 0 4V4.66711C0 4.97907 0.145589 5.27316 0.393664 5.46232L10.1814 12.9253C10.5337 13.194 11.0209 13.1988 11.3784 12.937L21.5907 5.46179C21.8479 5.27346 22 4.97372 22 4.65487V4C22 3.44772 21.5523 3 21 3Z"
        fill={fill}
      />
      <path
        d="M10.1814 14.2222L1.60634 7.6838C0.948135 7.18193 0 7.65129 0 8.479V17.9995C0 18.5518 0.447716 18.9995 1 18.9995H21C21.5523 18.9995 22 18.5518 22 17.9995V8.43025C22 7.61155 21.07 7.13976 20.4093 7.62333L11.3784 14.2339C11.0209 14.4956 10.5337 14.4908 10.1814 14.2222Z"
        fill={fill}
      />
    </svg>
  );
};
