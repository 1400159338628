import React from "react";

import { Box, Text } from "../components";
import { Arrow, Play, Store } from "../icons";

const Download = ({ title, Icon }) => {
  return (
    <Box display="flex" flexDirection="column" mr={5} textAlign="left">
      <Text
        style={{
          fontSize: "14px",
          color: "#828386",
        }}
      >
        Download our
      </Text>
      <Text
        style={{
          fontSize: "24px",
          color: "#1BA7C1",
          fontWeight: "800",
        }}
      >
        {title}
      </Text>
      <Box display="flex" alignSelf="flex-end" mr="-30px" mt={3}>
        <Box mr={3}>
          <Icon width={30} height={30} />
        </Box>
        <Box alignSelf="center">
          <Arrow />
        </Box>
      </Box>
    </Box>
  );
};

export const Landing = () => {
  return (
    <Box
      width={[1, 0.5]}
      mt={[4, 6]}
      mb={4}
      pl={[3, 5]}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" justifyContent="flex-start">
        <Text variant="header">black</Text>
        <Text variant="headerSecondary" color="grey">
          book
        </Text>
      </Box>
      <Box mt={4} textAlign="left">
        <Text variant="h1">Find trusted talent from your extended network</Text>
      </Box>
      <Box display="flex" mt={4}>
        <Download title="iPhone app" Icon={Store} />
        <Download title="Android app" Icon={Play} />
      </Box>
    </Box>
  );
};
