const palette = {
  grayDarkest: "#19181F",
  grayDarker: "#24202A",
  grayDark: "#47464C",
  gray: "#A0A0A0",
  grayCaption: "#979797",
  grayButton: "#27262D",

  cyan: "#1BA7C1",

  success: "#25D366",

  black: "#000",
  white: "#FFF",
};

const header = {
  fontWeight: "900",
  fontSize: 16,
  letterSpacing: "10px",
  lineHeight: "16px",
  textTransform: "uppercase",
};

const theme = {
  fonts: {
    body: "Poppins",
  },
  colors: {
    mainBackground: palette.grayDarkest,
    mainForeground: palette.white,
    secondaryForeground: palette.gray,
    secondaryColour: palette.cyan,
    cardBackground: palette.grayDark,
    border: palette.grayDarker,
    button: palette.grayButton,
    caption: palette.grayCaption,
    success: palette.success,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  spacing: {
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
  },
  breakpoints: ["40em", "52em", "64em", "80em"],
  texts: {
    header: {
      ...header,
      color: palette.white,
    },
    headerSecondary: {
      ...header,
      color: palette.grey,
    },
    caption: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
      color: palette.grayCaption,
    },
    h1: {
      fontWeight: "800",
      fontSize: "36px",
      color: palette.white,
    },
    h2: {
      fontWeight: "800",
      fontSize: "22px",
      color: palette.white,
    },
    h3: {
      fontWeight: "800",
      fontSize: "18px",
    },
    h6: {
      fontWeight: "500",
      fontSize: "10px",
      lineHeight: "15px",
      textAlign: "center",
    },
    focus: {
      color: palette.cyan,
    },
    secondaryFocus: {
      color: palette.grayCaption,
    },
  },
  inputs: {
    text: {
      background: palette.grayButton,
      height: "64px",
      border: "none",
      borderRadius: "3px",
      color: palette.white,
      fontSize: "16px",
      padding: "4px",
      paddingLeft: "12px",
    },
  },
};

export default theme;
