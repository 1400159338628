import React from "react";

export const Twitter = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6823 7.07715L10.7299 7.87012L9.93513 7.77302C7.04202 7.40081 4.51452 6.13853 2.36854 4.01856L1.31939 2.96666L1.04915 3.74345C0.476886 5.47503 0.842499 7.30371 2.03472 8.53362C2.67056 9.21331 2.5275 9.31041 1.43066 8.90583C1.04915 8.77637 0.71533 8.67927 0.683537 8.72782C0.572264 8.8411 0.953773 10.3138 1.2558 10.8963C1.6691 11.7055 2.5116 12.4985 3.43358 12.9678L4.2125 13.34L3.29052 13.3562C2.40033 13.3562 2.36854 13.3723 2.46391 13.7122C2.78184 14.7641 4.03764 15.8807 5.4365 16.3662L6.42207 16.706L5.56367 17.2239C4.29198 17.9683 2.79773 18.3891 1.30349 18.4214C0.58816 18.4376 0 18.5024 0 18.5509C0 18.7127 1.93934 19.619 3.06797 19.975C6.45386 21.0269 10.4756 20.5738 13.4959 18.7775C15.6419 17.499 17.7879 14.9583 18.7893 12.4985C19.3298 11.1876 19.8703 8.79255 19.8703 7.64355C19.8703 6.89914 19.918 6.80204 20.8081 5.91197C21.3327 5.39411 21.8255 4.82771 21.9209 4.66588C22.0798 4.3584 22.0639 4.3584 21.2532 4.63351C19.9021 5.119 19.7113 5.05427 20.3789 4.32603C20.8717 3.80818 21.4599 2.86956 21.4599 2.59445C21.4599 2.5459 21.2214 2.62682 20.9512 2.77246C20.6651 2.93429 20.0292 3.17704 19.5523 3.32269L18.6939 3.5978L17.915 3.06376C17.4858 2.77246 16.8818 2.4488 16.5639 2.3517C15.7531 2.12514 14.5132 2.15751 13.782 2.41644C11.795 3.14467 10.5392 5.0219 10.6823 7.07715Z"
        fill={fill}
      />
    </svg>
  );
};
