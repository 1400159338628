import styled from "styled-components";
import { color, space, variant } from "styled-system";
import css from "@styled-system/css";

export const Button = styled.button`
  ${color}
  ${space}
  ${variant({
    variants: {
      primary: {
        color: "white",
        fontWeight: "bold",
        borderRadius: "3px",
        p: "6px 12px 6px 12px",
        bg: "mainBackground",
        borderWidth: `1px`,
        borderStyle: `solid`,
        borderColor: "secondaryColour",
      },
      secondary: {
        color: "white",
        borderStyle: "none",
        fontWeight: "bold",
        textDecoration: "underline",
        bg: "mainBackground",
      },
      cta: {
        color: "white",
        fontWeight: "bold",
        borderRadius: "3px",
        p: "6px 12px 6px 12px",
        bg: "secondaryColour",
        borderWidth: `1px`,
        borderStyle: `solid`,
        borderColor: "secondaryColour",
      },
    },
  })}
  ${css({
    "&:hover": {
      cursor: "pointer",
    },
    "&:disabled": {
      opacity: "20%",
    },
  })}
`;
