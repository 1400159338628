import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import { Box, Text, Button } from "../";

import { ReactComponent as SuccessSvg } from "../../icons/success.svg";
import useStore from "../../hooks/useStore";

export const SubmitSuccess = ({ handleReset }) => {
  const {
    values: { firstName, submitter },
  } = useFormikContext();
  const { progress, setProgress } = useStore((state) => ({ ...state }));
  const history = useHistory();

  useEffect(() => {
    if (!progress) {
      history.push("name");
    }
  }, [progress, history]);

  const handleRestart = () => {
    handleReset({
      values: {
        submitter: {
          ...submitter,
        },
      },
    });
    setProgress(0);
  };

  return (
    <>
      <Box display="flex" pl={[3, 5]} pr={[3, 5]}>
        <Box
          display="flex"
          flexDirection="column"
          width={[0.8, 1 / 3]}
          mt={[3, 6]}
          textAlign="left"
        >
          <Box mb={1}>
            <Text role="img" variant="h2">
              🙌
            </Text>
          </Box>
          <Text variant="h2" color="white">
            Thank you for recommending&nbsp;
            <Text variant="focus">{firstName}</Text>!
          </Text>
          <Box mt={[5, 6]}>
            <Text variant={["h2", "h1"]}>Can you recommend someone else?</Text>
          </Box>
          <Box mt={3}>
            <Button variant="primary" onClick={handleRestart}>
              Recommend another contact
            </Button>
          </Box>
        </Box>
        <Box
          ml={6}
          flexGrow={1}
          flexDirection="column"
          alignItems="center"
          display={["none", "flex"]}
        >
          <SuccessSvg />
          <Box mt={3}>
            <Text variant="h3" color="white">
              Find and manage your contacts using
            </Text>
          </Box>
          <Button variant="cta" mt={3}>
            The Blackbook app
          </Button>
        </Box>
      </Box>
      <Box bottom={0} position="absolute" width={1} display={["block", "none"]}>
        <Text variant="h3" color="caption">
          Find and manage your contacts using{" "}
          <Box>
            <Text color="white">
              <a href="#">the Blackbook app</a>
            </Text>
          </Box>
        </Text>
        <Box
          height={50}
          width={1}
          bottom={0}
          backgroundImage="linear-gradient(0deg, #1BA7C1 0%, rgba(55, 20, 157, 0) 100%);
        "
        ></Box>
      </Box>
    </>
  );
};
