import React from "react";
import { Field, useFormikContext } from "formik";

import { Box, Text, TextInput } from "../";

import { FormPage } from "./";

const StyledInput = (props) => (
  <TextInput variant="text" width={1} mt={2} {...props} />
);

export const ReferrerName = () => {
  const {
    values: { firstName },
    isValid,
  } = useFormikContext();

  const handleSubmit = (e) => {
    if (!isValid) {
      e.preventDefault();
    }
  };

  const title = (
    <>
      What&apos;s your name? So they know who recommended{" "}
      {<Text variant="focus">{firstName}</Text>}
    </>
  );

  return (
    <FormPage
      step={4}
      onNext={handleSubmit}
      backTo="socials"
      pageTitle={title}
      pageBody={
        <>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            mt={[3, 4]}
          >
            <Box flex={1} mr={["0px", 2]}>
              <Field
                autoComplete="given-name"
                as={StyledInput}
                id="submitter.firstName"
                name="submitter.firstName"
                placeholder="First name"
                type="text"
              />
            </Box>
            <Box flex={1}>
              <Field
                autoComplete="family-name"
                as={StyledInput}
                id="submitter.lastName"
                name="submitter.lastName"
                placeholder="Last name"
                type="text"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignContent="center"
            mt={3}
          >
            <label htmlFor="acceptedTerms">
              <Text variant="caption" mr={1}>
                {`I confirm that ${firstName} has a legitimate interest in
          being recommended`}
              </Text>
            </label>
            <Field name="acceptedTerms" id="acceptedTerms" type="checkbox" />
          </Box>
        </>
      }
    />
  );
};
