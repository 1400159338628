import React from "react";

import { Box, Text } from "..";

export const Header = () => {
  return (
    <Box p="2rem" bg="mainBackground" display="flex" justifyContent="center">
      <Text variant="header">black</Text>
      <Text variant="headerSecondary" color="grey">
        book
      </Text>
    </Box>
  );
};
