import React from "react";

export const Youtube = ({ fill }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5402 5.43156C21.2872 4.44807 20.5417 3.67362 19.5951 3.41078C17.8796 2.93311 11 2.93311 11 2.93311C11 2.93311 4.12037 2.93311 2.40475 3.41078C1.45812 3.67362 0.712625 4.44807 0.459625 5.43156C0 7.21401 0 10.9331 0 10.9331C0 10.9331 0 14.6521 0.459625 16.4347C0.712625 17.4181 1.45812 18.1926 2.40475 18.4556C4.12037 18.9331 11 18.9331 11 18.9331C11 18.9331 17.8796 18.9331 19.5951 18.4556C20.5417 18.1926 21.2872 17.4181 21.5402 16.4347C22 14.6521 22 10.9331 22 10.9331C22 10.9331 22 7.21401 21.5402 5.43156"
        fill={fill}
      />
      <path
        d="M8.93701 14.7597V7.80322L14.437 11.2816L8.93701 14.7597Z"
        fill="#19181F"
      />
    </svg>
  );
};
