import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import { Box, Text, Button } from "../";
import useStore from "../../hooks/useStore";

export const FormPage = ({ pageTitle, pageBody, onNext, step, backTo }) => {
  const { progress, setProgress } = useStore((state) => ({ ...state }));
  const { isValid, isSubmitting, validateForm } = useFormikContext();
  const history = useHistory();

  useEffect(() => {
    if (!progress) {
      history.push("name");
    }
    setProgress(step);
    validateForm();
  }, [progress, history, validateForm, step, setProgress]);

  const handleNext = () => {
    setProgress(step + 1);
    onNext();
  };

  return (
    <Box width={[1, 0.7]} pl={[3, 5]} pr={[3, 5]} mb={4}>
      <Box textAlign="left">
        <Text variant="caption">Recommend a contact</Text>
        <Box mt={2}>
          <Text variant="h1">{pageTitle}</Text>
        </Box>
      </Box>
      {pageBody}
      <Box display="flex" justifyContent="flex-end" alignItems="baseline">
        {backTo && (
          <Link to={backTo}>
            <Button mt={3} mr={2} variant="secondary">
              Back
            </Button>
          </Link>
        )}
        <Box mt={3}>
          <Button
            variant="primary"
            type="submit"
            disabled={Boolean(!isValid || isSubmitting)}
            onClick={handleNext}
          >
            {step === 4 ? "Submit" : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
