import React from "react";
import styled from "styled-components";
import { color, layout, background } from "styled-system";

import useStore from "../../hooks/useStore";
import { Box } from "../Box";

const SlideBar = styled.div`
  ${color}
  ${layout}
  ${background}
  transition: width cubic-bezier(1,-0.01, 0, 0.98) 0.5s;
`;

export const ProgressBar = () => {
  const progress = useStore((state) => state.progress);
  const width = (progress / 5) * 100;
  return (
    <Box position="absolute" top={0} width={1}>
      <SlideBar height="4px" bg="secondaryColour" width={`${width}%`} />
    </Box>
  );
};
