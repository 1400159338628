import React from "react";

export const Play = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 133 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 125.594V6.90568C9.5 3.83149 12.8236 1.90637 15.4902 3.43594L118.951 62.7803C121.631 64.3173 121.631 68.1827 118.951 69.7197L15.4902 129.064C12.8236 130.594 9.5 128.669 9.5 125.594Z"
        fill="#00D5FF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="2"
        width="112"
        height="128"
      >
        <path
          d="M9.5 125.594V6.90568C9.5 3.83149 12.8236 1.90637 15.4902 3.43594L118.951 62.7803C121.631 64.3173 121.631 68.1827 118.951 69.7197L15.4902 129.064C12.8236 130.594 9.5 128.669 9.5 125.594Z"
          fill="#F83648"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M111.5 24.5L6 130.5L12.5 146L70.5 130.5L128 69.5L111.5 24.5Z"
          fill="#F83648"
        />
      </g>
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="2"
        width="112"
        height="128"
      >
        <path
          d="M9.5 125.594V6.90568C9.5 3.83149 12.8236 1.90637 15.4902 3.43594L118.951 62.7803C121.631 64.3173 121.631 68.1827 118.951 69.7197L15.4902 129.064C12.8236 130.594 9.5 128.669 9.5 125.594Z"
          fill="#00F177"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          d="M-2 -3L111 105L132.5 75.5L127 33.5L111 5L48 -11L-2 -3Z"
          fill="#00F177"
        />
      </g>
      <mask
        id="mask2"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="6"
        y="24"
        width="122"
        height="107"
      >
        <path
          d="M111.5 24.5L6 130.5H33H70.5L128 69.5L111.5 24.5Z"
          fill="#FFC901"
        />
      </mask>
      <g mask="url(#mask2)">
        <mask
          id="mask3"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="9"
          y="2"
          width="112"
          height="128"
        >
          <path
            d="M9.5 125.594V6.90568C9.5 3.83149 12.8236 1.90637 15.4902 3.43594L118.951 62.7803C121.631 64.3173 121.631 68.1827 118.951 69.7197L15.4902 129.064C12.8236 130.594 9.5 128.669 9.5 125.594Z"
            fill="#FFC901"
          />
        </mask>
        <g mask="url(#mask3)">
          <path
            d="M-2 -3L111 105L132.5 75.5L127 33.5L111 5L48 -11L-2 -3Z"
            fill="#FFC901"
          />
        </g>
      </g>
    </svg>
  );
};
