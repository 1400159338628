import React from "react";
import { Box, Text } from "../";

import theme from "../../theme";

export const SocialButton = ({ icon, label, isActive, onClick }) => {
  const Icon = icon;
  const boxColor = isActive
    ? theme.colors.caption
    : theme.colors.mainForeground;

  return (
    <Box
      size={[64, 80]}
      bg="button"
      p={1}
      mr={[3, 3, 0]}
      mt={[3]}
      borderRadius="3px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      <Icon fill={boxColor} />
      <Text variant="h6" mt={0} color={boxColor}>
        {label}
      </Text>
    </Box>
  );
};
