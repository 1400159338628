import React from "react";

export const Store = ({ fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 125 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="125" height="125" rx="27" fill="#1AB8FB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4219 41.1841L37.8695 72.8654H24C20.6863 72.8654 18 75.5517 18 78.8654C18 82.1791 20.6863 84.8654 24 84.8654H77C77 78.238 71.6274 72.8654 65 72.8654H51.7756L75.6869 32.0329C77.3614 29.1734 76.4007 25.4979 73.5412 23.8234C70.6817 22.1489 67.0062 23.1095 65.3317 25.969L63.3992 29.2691L61.854 26.5928C60.1972 23.723 56.5276 22.7398 53.6579 24.3966C50.7881 26.0535 49.8048 29.723 51.4617 32.5928L56.4219 41.1841ZM26.3825 93.0863C24.708 95.9458 25.6687 99.6213 28.5282 101.296C31.3877 102.97 35.0632 102.01 36.7377 99.1502L41.9593 90.2333L38.6514 88.2962C34.7593 86.017 29.7565 87.3246 27.4774 91.2166L26.3825 93.0863ZM95.2174 84.8654L100.248 93.5784C101.905 96.4482 100.921 100.118 98.0517 101.775C95.182 103.431 91.5124 102.448 89.8556 99.5784L67.8924 61.537C64.5787 55.7975 66.5452 48.4584 72.2847 45.1447L88.2892 72.8654H102C105.314 72.8654 108 75.5517 108 78.8654C108 82.1791 105.314 84.8654 102 84.8654H95.2174Z"
        fill="white"
      />
    </svg>
  );
};
